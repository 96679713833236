import React, { useCallback } from 'react';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';

import { useTranslation } from 'src/i18n';

import classes from './ToggleSwitch.module.scss';

const RenderOption = ({
  ns,
  option,
  isSelected,
  onChange,
  variant = 'dark',
  size = 'large',
  upperCase = false,
  disabled = false,
  trackingData,
}) => {
  const { t } = useTranslation(ns);
  const labelOptions = Object?.keys(option?.labelOptions || {})?.reduce((acc, key) => {
    acc[key] = t(option?.labelOptions?.[key]);
    return acc;
  }, {});

  return (
    <LinkButton
      variant={isSelected ? variant : 'tertiary'}
      key={option?.value}
      size={size}
      onClick={() => onChange(option)}
      leftIcon={option?.icon}
      styles={{
        root: classnames(classes.toggleBtn, {
          [classes.toggleBtn__isSelected]: isSelected,
          [classes.toggleBtn__upperCase]: !!upperCase,
        }),
      }}
      disabled={option.disabled || disabled}
      trackingData={trackingData}
    >
      <Typography size="12" color="inherit">
        {t(option?.label, { ...(labelOptions || {}) })}
      </Typography>
    </LinkButton>
  );
};

const ToggleSwitch = ({
  ns = 'NS_APP_GLOBALS',
  options,
  value,
  onChange,
  variant,
  size,
  upperCase,
  trackingData,
  disabled,
}) => {
  const onChangeHandler = useCallback(option => onChange?.(option), [onChange]);

  return (
    <div className={classes.optionsList}>
      {options?.map(option => {
        const isSelected = value === option?.value;
        return (
          <RenderOption
            ns={ns}
            key={option?.value}
            option={option}
            disabled={disabled}
            onChange={onChangeHandler}
            isSelected={isSelected}
            variant={variant}
            size={size}
            upperCase={upperCase}
            trackingData={trackingData}
          />
        );
      })}
    </div>
  );
};

export default ToggleSwitch;
